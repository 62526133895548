exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-acodaily-jsx": () => import("./../../../src/pages/acodaily.jsx" /* webpackChunkName: "component---src-pages-acodaily-jsx" */),
  "component---src-pages-acodaily-magang-jsx": () => import("./../../../src/pages/acodaily-magang.jsx" /* webpackChunkName: "component---src-pages-acodaily-magang-jsx" */),
  "component---src-pages-audit-website-jsx": () => import("./../../../src/pages/audit-website.jsx" /* webpackChunkName: "component---src-pages-audit-website-jsx" */),
  "component---src-pages-blogs-jsx": () => import("./../../../src/pages/blogs.jsx" /* webpackChunkName: "component---src-pages-blogs-jsx" */),
  "component---src-pages-contacts-jsx": () => import("./../../../src/pages/contacts.jsx" /* webpackChunkName: "component---src-pages-contacts-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-jasa-pembuatan-website-di-bone-jsx": () => import("./../../../src/pages/jasa-pembuatan-website-di-bone.jsx" /* webpackChunkName: "component---src-pages-jasa-pembuatan-website-di-bone-jsx" */),
  "component---src-pages-jasa-pembuatan-website-jsx": () => import("./../../../src/pages/jasa-pembuatan-website.jsx" /* webpackChunkName: "component---src-pages-jasa-pembuatan-website-jsx" */),
  "component---src-pages-links-jsx": () => import("./../../../src/pages/links.jsx" /* webpackChunkName: "component---src-pages-links-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-works-jsx": () => import("./../../../src/pages/works.jsx" /* webpackChunkName: "component---src-pages-works-jsx" */),
  "component---src-templates-blog-js": () => import("./../../../src/templates/blog.js" /* webpackChunkName: "component---src-templates-blog-js" */),
  "component---src-templates-work-template-js": () => import("./../../../src/templates/workTemplate.js" /* webpackChunkName: "component---src-templates-work-template-js" */)
}

